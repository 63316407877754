import { useContext, useEffect } from "react";
import { Space } from "@gocardless/flux-react";
import { GlobalState } from "src/state";
import { Routes } from "src/components/shared/Router";
import { Logger } from "src/common/logger";
import {
  BillingRequestFlowResource,
  BillingRequestResource,
  InstalmentTemplateResource,
  PaylinkResource,
  PlanResource,
} from "@gocardless/api/dashboard/types";
import Heading from "src/components/shared/Heading";
import BillingRequestDescriptionPanel from "src/components/shared/BillingRequestDescription/BillingRequestDescriptionPanel";
import { useSegment } from "src/shared/Segment/useSegment";
import { TrackingEvents } from "src/common/trackingEvents";
import { trackBillingRequestFlowEvents } from "src/common/api";
import { NonSegmentTrackingEvents } from "src/common/nonSegmentTrackingEvents";

import CustomerDetailsForm from "./CustomerDetailsForm";

const CollectCustomerDetails = () => {
  const {
    push,
    goBack,
    billingRequest,
    billingRequestFlow,
    plan,
    instalmentTemplate,
    setIsEditClicked,
    isEditClicked,
    paylink,
  } = useContext(GlobalState);

  const log = Logger("PageCollectCustomerDetails", {
    isEditClickedInitialVal: isEditClicked,
  });

  const { sendEvent } = useSegment();

  useEffect(() => {
    // In addition to tracking this event on Segment, we also want to track in
    // our internal service. This allows us use this data in our conversion dashboards
    // on Looker.
    trackBillingRequestFlowEvents(
      NonSegmentTrackingEvents.COLLECT_CUSTOMER_DETAILS_STARTED,
      billingRequestFlow
    )?.catch((error) => {
      log({ error });
    });

    // after cookie policy is changed to opt-out event
    // will be sent as soon as segment is loaded.
    // If user do not consent to cookie and reload the screen, segment will not be loaded
    sendEvent(TrackingEvents.CUSTOMER_DETAILS_STEP_STARTED);
  }, []);

  return (
    <CollectCustomerDetailsView
      billingRequest={billingRequest as BillingRequestResource}
      billingRequestFlow={billingRequestFlow as BillingRequestFlowResource}
      paylink={paylink}
      plan={plan}
      instalmentTemplate={instalmentTemplate}
      onContinue={() => {
        log({
          message: "Reset isEditClicked state on continue",
        });
        setIsEditClicked(false);
        push(Routes.Flow, {
          origin: "PageCollectCustomerDetails",
          reason: "collected customer details, going to next step",
        });
      }}
      onCancel={() => {
        log({
          message: "Cancels the edit customer details",
        });
        setIsEditClicked(false);
        goBack();
      }}
      sendEvent={sendEvent}
    />
  );
};

interface CollectCustomerDetailsViewProps {
  billingRequest: BillingRequestResource;
  billingRequestFlow: BillingRequestFlowResource;
  paylink: PaylinkResource | undefined;
  plan?: PlanResource;
  instalmentTemplate?: InstalmentTemplateResource;
  onContinue: () => void;
  onCancel: () => void;
  sendEvent: (name: string, params?: {}) => void;
}

export const CollectCustomerDetailsView = ({
  billingRequest,
  billingRequestFlow,
  paylink,
  plan,
  instalmentTemplate,
  onContinue,
  onCancel,
  sendEvent,
}: CollectCustomerDetailsViewProps) => {
  return (
    <>
      <Heading billingRequest={billingRequest} paylink={paylink} />
      <Space v={1.5} />
      <BillingRequestDescriptionPanel
        billingRequest={billingRequest}
        billingRequestFlow={billingRequestFlow}
        paylink={paylink}
        plan={plan}
        instalmentTemplate={instalmentTemplate}
        page={Routes.CollectCustomerDetails}
      />

      <Space v={2} />

      <CustomerDetailsForm
        billingRequest={billingRequest}
        billingRequestFlow={billingRequestFlow as BillingRequestFlowResource}
        onContinue={onContinue}
        onCancel={onCancel}
        sendEvent={sendEvent}
      />
    </>
  );
};

export default CollectCustomerDetails;
