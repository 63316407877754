import {
  AddressDetailSelfResponseBody,
  AddressLookupCreateRequestBody,
  AddressDetailResource,
  AddressLookupResource,
} from "@gocardless/api/dashboard/types";
import { addressLookupCreate } from "@gocardless/api/dashboard/address-lookup";
import { Endpoint } from "@gocardless/api/dashboard/common/endpoints";
import api from "@gocardless/api/utils/api";
import { buildUrl } from "@gocardless/api/utils/build-url";
import {
  Box,
  Color,
  Field,
  Input,
  Label,
  useTheme,
  FontWeight,
  Text,
  JustifyContent,
  Overflow,
  FormFieldStatus,
  Hint,
  ColorPreset,
  ButtonVariant,
  Link,
} from "@gocardless/flux-react";
import { t, Trans } from "@lingui/macro";
import { I18n } from "@lingui/core";
import { useCallback, useState, useRef, useEffect } from "react";
import Autosuggest, {
  ChangeEvent,
  OnSuggestionSelected,
  RenderInputComponentProps,
  RenderSuggestionParams,
  RenderSuggestionsContainerParams,
  SuggestionsFetchRequestedParams,
  ThemeKey,
} from "react-autosuggest";
import { NestDataObject } from "react-hook-form";
import { shouldMockRequests } from "src/common/utils";
import {
  addressDetailSelf as mockAddressDetailSelf,
  addressLookupCreate as mockAddressLookupCreate,
} from "src/fixtures";
import { ErrorType } from "src/state";
import { Logger } from "src/common/logger";
import { TrackingEvents } from "src/common/trackingEvents";
import { CustomerDetailsObject } from "src/config/customer-details/customer-details-config";

export interface AddressLookupProps {
  errors: NestDataObject<CustomerDetailsObject>;
  onSelect: (address: AddressDetailResource) => void;
  switchToFullForm: () => void;
  setAppError: (error?: ErrorType) => void;
  i18n: I18n;
  sendEvent: (name: string, params?: {}) => void;
}

/**
 * Docs for react-autosuggest: https://github.com/moroshko/react-autosuggest
 */
export const AddressLookup = ({
  errors,
  onSelect,
  switchToFullForm,
  setAppError,
  i18n,
  sendEvent,
}: AddressLookupProps) => {
  const [addressLookups, setAddressLookups] = useState<AddressLookupResource[]>(
    []
  );
  const [inputValue, setInputValue] = useState<string>("");

  const { theme } = useTheme();
  const error =
    errors["address_line1"] || errors["postal_code"] || errors["city"];
  const log = Logger("AddressLookup");
  const updateAddressLookups = useCallback(
    (search_term: string, id?: string) => {
      if (
        !search_term ||
        typeof search_term !== "string" ||
        search_term.length < 3
      ) {
        setAddressLookups([]);
        return;
      }
      createAddressLookups({ search_term, id })
        .then(({ address_lookups }) => {
          setAddressLookups(address_lookups ?? []);
        })
        .catch((err) => {
          // We do not want to break the flow when the address lookup
          // fails or if there is an issue with the address lookup
          // endpoint. We need to fallback allowing to enter address
          // manually
          log({
            event: "address_lookup_failed",
            component: "AddressLookup",
            status: err?.response?.status,
          });
        });
    },
    [setAppError]
  );

  /**
   * We want to update suggestions when:
   * - input value changed - to get new results
   * - escape pressed - to clean the list of suggestions
   */
  const onSuggestionsFetchRequested = useCallback(
    ({ value, reason }: SuggestionsFetchRequestedParams) => {
      if (["input-changed", "escape-pressed"].includes(reason)) {
        updateAddressLookups(value);
      }
    },
    [updateAddressLookups]
  );

  /**
   * When clicking on a suggestion that has multiple addresses,
   * the input field will update with the "address_text" value
   * of that suggestion
   */
  const getSuggestionValue = useCallback(
    (clickedSuggestion: AddressLookupResource) =>
      clickedSuggestion.address_text ?? "",
    []
  );

  /**
   * The suggestions are rendered as an <ul> element, and this method
   * returns the value of <li> from that list.
   */
  const renderSuggestion = useCallback(
    (
      suggestion: AddressLookupResource,
      { isHighlighted }: RenderSuggestionParams
    ) => <LookupResult isHighlighted={isHighlighted} suggestion={suggestion} />,
    []
  );

  /**
   * Because the container should include a footer
   * we handle how the container gets rendered:
   *
   * <container>
   *   <scrollable box>
   *     <list of suggestions as children>
   *        - the elements of this list are render by "renderSuggestion" method
   *   </scrollable box>
   *   <footer>
   * </container
   */
  const renderSuggestionsContainer = useCallback(
    ({ containerProps, children }: RenderSuggestionsContainerParams) => {
      if (!children) {
        return null;
      }
      return (
        <div {...containerProps}>
          <Box
            overflowY={Overflow.Scroll}
            css={{
              maxHeight: "200px",
            }}
          >
            {children}
          </Box>
          <Box
            bg={Color.Greystone_100}
            gutterH={1}
            gutterV={1}
            css={{
              borderTop: `1px solid ${theme.color(
                ColorPreset.BorderOnLight_04
              )}`,
              borderBottomLeftRadius: theme.tokens.borderRadiusBase,
              borderBottomRightRadius: theme.tokens.borderRadiusBase,
            }}
          >
            <Trans id="collect-customer-details-page.billing-address-suggestions.footer-text">
              <b>{addressLookups.length} results</b> (keep typing your address
              to refine results
              <Link
                variant={ButtonVariant.InlineUnderlined}
                href=""
                css={{
                  display: "inline-block",
                  fontWeight: FontWeight.SemiBold,
                  fontSize: "14px",
                }}
                onClick={() => {
                  switchToFullForm();
                  sendEvent(
                    TrackingEvents.CUSTOMER_DETAILS_STEP_MANUALLY_ENTER_ADDRESS_CLICKED
                  );
                }}
              >
                or enter your address manually
              </Link>
              )
            </Trans>
          </Box>
        </div>
      );
    },
    [addressLookups.length, switchToFullForm, theme]
  );

  /**
   * It renders everything to preserver the styles of the form.
   */
  type InputProps = Omit<RenderInputComponentProps, "children">;

  const renderInputComponent = useCallback(
    (inputProps: InputProps) => {
      const suggestionContainerIsClosed = addressLookups.length === 0;
      return (
        <Field>
          <Label>
            <Trans id="collect-customer-details-page.billing-address.label">
              Billing address
            </Trans>
          </Label>
          <Input {...inputProps} name="address_lookup" />
          {error && suggestionContainerIsClosed && (
            <Hint status={FormFieldStatus.Danger}>
              {["required", "invalid", "pattern"].includes(error.type) && (
                <Trans id="collect-customer-details-page.billing-address.invalid-message">
                  Some field of the billing address is invalid
                </Trans>
              )}
            </Hint>
          )}
        </Field>
      );
    },
    [addressLookups.length, error]
  );

  const onChangeInput = useCallback(
    (_event: React.FormEvent<HTMLElement>, { newValue }: ChangeEvent) =>
      setInputValue(newValue),
    []
  );

  const onSuggestionSelected = useCallback<
    OnSuggestionSelected<AddressLookupResource>
  >(
    (_event, { suggestion }) => {
      if (suggestion.has_nested_results) {
        updateAddressLookups(inputValue, suggestion.id);
      } else {
        getAddressDetails(suggestion.id as string)
          .then(({ address_details }) => {
            onSelect(address_details as AddressDetailResource);
          })
          .catch((err) => {
            // We do not want to break the flow when the address lookup
            // fails or if there is an issue with the address lookup
            // endpoint. We need to fallback allowing to enter address
            // manually
            log({
              event: "address_lookup_failed",
              component: "AddressLookup",
              status: err?.response?.status,
            });
          });
      }
    },
    [inputValue, onSelect, setAppError, updateAddressLookups]
  );

  const inputProps = {
    placeholder: i18n._(
      t({
        id: "collect-customer-details-page.billing-address.input-placeholder",
        message: "Start typing your postcode and select",
      })
    ),
    value: inputValue,
    onChange: onChangeInput,
  };

  /**
   * The details for this can be found here:
   * https://github.com/moroshko/react-autosuggest#theme-optional
   */
  const autoSuggestTheme: Partial<
    Record<ThemeKey, string | React.CSSProperties>
  > = {
    suggestionsContainerOpen: {
      position: "absolute",
      borderRadius: theme.tokens.borderRadiusBase,
      background: theme.color(Color.White),
      width: "100%",
      border: `1px solid ${theme.color(ColorPreset.BorderOnLight_04)}`,
    },
    inputFocused: { position: "relative" },
    container: {
      position: "relative",
      zIndex: 2,
    },
    suggestionsList: {
      margin: 0,
      padding: "8px",
      listStyleType: "none",
    },
  };

  return (
    <Autosuggest
      theme={autoSuggestTheme}
      suggestions={addressLookups}
      onSuggestionsClearRequested={() => {}}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      getSuggestionValue={getSuggestionValue}
      inputProps={inputProps}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderInputComponent}
      renderSuggestionsContainer={renderSuggestionsContainer}
      onSuggestionSelected={onSuggestionSelected}
      alwaysRenderSuggestions={true}
    />
  );
};

const LookupResult = ({
  suggestion,
  isHighlighted,
}: {
  suggestion: AddressLookupResource;
  isHighlighted: boolean;
}) => {
  const boxRef = useRef<HTMLDivElement>(null);
  /**
   * when navigating using arrow keys we need to scroll selected elemeents into view
   */
  useEffect(() => {
    if (isHighlighted && boxRef.current) {
      boxRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [isHighlighted]);

  return (
    <Box
      css={{ cursor: "pointer" }}
      ref={boxRef}
      gutterV={0.5}
      gutterH={1}
      borderRadius={1}
      flexWrap="wrap"
      layout="flex"
      justifyContent={JustifyContent.SpaceBetween}
      bg={isHighlighted ? Color.Greystone_1400 : undefined}
      color={isHighlighted ? Color.White : undefined}
    >
      <Text weight={FontWeight.SemiBold}>{suggestion.address_text}</Text>
      <Text>{suggestion.description}</Text>
    </Box>
  );
};

// similar to `fetcher` from @gocardless/api/utils/fetcher.ts
async function getAddressDetails(
  addressDetailId: string
): Promise<AddressDetailSelfResponseBody> {
  if (shouldMockRequests()) {
    return mockAddressDetailSelf();
  }

  const url = buildUrl({
    endpoint: Endpoint.AddressDetailSelf,
    endpointParams: { addressDetailId },
  });
  const response = await api.API.get(url, { credentials: "include" });
  return response.json();
}

async function createAddressLookups(params: AddressLookupCreateRequestBody) {
  if (shouldMockRequests()) {
    return mockAddressLookupCreate(params);
  }

  return addressLookupCreate(params);
}
