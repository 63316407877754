import { useCallback, useState } from "react";
import {
  AddressDetailResource,
  BillingRequestResource,
  CustomerBillingDetailClass,
  CustomerBillingDetailObject,
} from "@gocardless/api/dashboard/types";
import {
  Box,
  ButtonVariant,
  FontWeight,
  Space,
  XYGrid,
} from "@gocardless/flux-react";
import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { FormContextValues, NestDataObject } from "react-hook-form";
import { TrackingEvents } from "src/common/trackingEvents";
import {
  BrandedComponentType,
  getBrandColorFor,
  isAchMxExperimentMandate,
} from "src/common/utils";
import BrandedButton from "src/components/shared/BrandedComponents/BrandedButton";
import {
  CustomerDetailsObject,
  FieldType,
  getAddressFieldTranslatedLabel,
} from "src/config/customer-details/customer-details-config";
import { ErrorType, PayerThemeType } from "src/state";
import { CountryCodes } from "src/common/country";

import { AddressLookup } from "../AddressLookup";
import CustomerField from "../CustomerField";

import ControlledCustomerDetailsField from "./ControlledCustomerDetailsField";

export interface AddressDetailsFieldsProps {
  canUseAddressLookup: boolean;
  errors: NestDataObject<CustomerDetailsObject>;
  addressConfigFields: Record<string, FieldType>;
  billingRequest: BillingRequestResource;
  payerTheme?: PayerThemeType;
  i18n: I18n;
  brfPrefilledCustomerData: CustomerBillingDetailObject;
  sendEvent: (name: string, params?: {}) => void;
  setValue: FormContextValues["setValue"];
  watch: FormContextValues["watch"];
  register: FormContextValues["register"];
  setAppError: (error?: ErrorType) => void;
  countryCode?: CountryCodes;
}

export const AddressDetailsFields = ({
  register,
  setAppError,
  canUseAddressLookup,
  setValue,
  watch,
  errors,
  addressConfigFields,
  billingRequest,
  brfPrefilledCustomerData,
  i18n,
  payerTheme,
  sendEvent,
  countryCode,
}: AddressDetailsFieldsProps) => {
  let templateAreas;
  const brCustomerBillingDetail =
    billingRequest?.resources?.customer_billing_detail;

  if (
    billingRequest.experimentation
      ?.is_eligible_for_ach_optional_address_experiments &&
    countryCode === CountryCodes.US
  ) {
    templateAreas = `
              'postal_code postal_code'
            `;
  } else if (isAchMxExperimentMandate(billingRequest, countryCode)) {
    templateAreas = `
      'region postal_code'
    `;
  } else {
    templateAreas = `
              'address_line1 address_line1'
              'address_line2 address_line2'
              'city postal_code'
              ${addressConfigFields?.region ? "'region region'" : ""}
            `;
  }

  const hasPopulatedAddress = Object.keys(addressConfigFields).some((key) =>
    Boolean(
      brCustomerBillingDetail?.[key as keyof CustomerBillingDetailClass] ||
        brfPrefilledCustomerData?.[key as keyof CustomerBillingDetailObject]
    )
  );

  const [shouldShowLookup, setShouldShowLookup] =
    useState<boolean>(!hasPopulatedAddress);

  const updateAddress = useCallback(
    (address: AddressDetailResource) => {
      const { address_line1, address_line2, address_line3, city, postal_code } =
        address;
      const firstAddressLine = address_line2
        ? `${address_line1}, ${address_line2}`
        : address_line1;
      setValue(
        [
          { address_line1: firstAddressLine },
          { address_line2: address_line3 },
          { city },
          { postal_code },
        ],
        true
      );
      setShouldShowLookup(false);
    },
    [setValue]
  );

  return (
    <>
      {shouldShowLookup && canUseAddressLookup ? (
        <>
          <AddressLookup
            errors={errors}
            setAppError={setAppError}
            onSelect={updateAddress}
            switchToFullForm={() => setShouldShowLookup(false)}
            i18n={i18n}
            sendEvent={sendEvent}
          />
          <GenericButton
            text={i18n._(
              t({
                id: "collect-customer-details-page.enter-address-link",
                message: "or click here to enter your address manually",
              })
            )}
            onClick={() => {
              setShouldShowLookup(false);
              sendEvent(
                TrackingEvents.CUSTOMER_DETAILS_STEP_MANUALLY_ENTER_ADDRESS_CLICKED
              );
            }}
            payerTheme={payerTheme}
          />
        </>
      ) : (
        <Box>
          <XYGrid templateAreas={templateAreas} rowGap={2} columnGap={1}>
            {Object.entries(addressConfigFields).map(([_, field]) => {
              const fieldName = field.name as keyof CustomerBillingDetailObject;

              const defaultValue =
                brCustomerBillingDetail?.[fieldName] ||
                brfPrefilledCustomerData?.[fieldName];

              if (field.options) {
                return (
                  <CustomerField
                    gridArea={field.name}
                    key={field.name}
                    fieldName={fieldName}
                    labelName={getAddressFieldTranslatedLabel(field)}
                    errors={errors}
                    register={register}
                    requiredField={field.required}
                    options={field.options}
                    defaultValue={defaultValue}
                    i18n={i18n}
                  />
                );
              }
              return (
                <ControlledCustomerDetailsField
                  gridArea={field.name}
                  key={field.name}
                  fieldName={fieldName}
                  labelName={getAddressFieldTranslatedLabel(field)}
                  errors={errors}
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  requiredField={field.required}
                  defaultValue={defaultValue}
                />
              );
            })}
          </XYGrid>
          {canUseAddressLookup && (
            <GenericButton
              text={i18n._(
                t({
                  id: "collect-customer-details-page.enter-another-address-link",
                  message: "Click here to find another address",
                })
              )}
              onClick={() => setShouldShowLookup(true)}
              payerTheme={payerTheme}
            />
          )}
        </Box>
      )}
    </>
  );
};

const GenericButton = ({
  text,
  onClick,
  payerTheme,
}: {
  text: string;
  onClick: () => void;
  payerTheme?: PayerThemeType;
}) => (
  <>
    <Space v={0.5} />
    <BrandedButton
      variant={ButtonVariant.InlineUnderlined}
      onClick={() => onClick()}
      tabIndex={-1}
      textColor={getBrandColorFor(BrandedComponentType.Link, payerTheme)}
      style={{ fontWeight: FontWeight.SemiBold, fontSize: "14px" }}
    >
      {text}
    </BrandedButton>
  </>
);
