import { Field, FormFieldStatus, Select, Option } from "@gocardless/flux-react";
import { FormContextValues, NestDataObject } from "react-hook-form";
import { CountryCodes, countryCodeToName } from "src/common/country";
import { t } from "@lingui/macro";
import { CustomerDetailsObject } from "src/config/customer-details/customer-details-config";
import { useLingui } from "@lingui/react";

export interface CountrySelectorProps {
  register: FormContextValues["register"];
  errors: NestDataObject<CustomerDetailsObject>;
  countryCode?: CountryCodes;
  onCountryChange: (country: CountryCodes) => void;
}

const DEFAULT_SELECT_VALUE = "DEFAULT";

export enum CountrySelectorDataTestIDs {
  CountryResidenceSelector = "country-residence-selector",
}

const CountrySelector = ({
  register,
  errors,
  countryCode,
  onCountryChange,
}: CountrySelectorProps) => {
  const { i18n } = useLingui();

  const sortedCountryCodes = Object.keys(CountryCodes).sort((a, b) => {
    const messageA = countryCodeToName(i18n)[a as CountryCodes];
    const messageB = countryCodeToName(i18n)[b as CountryCodes];

    // // The enum containing country codes alphabetically
    // // does not match the actual names alphabetically
    return messageA.localeCompare(messageB);
  });
  return (
    <Field>
      <Select
        name="country_code"
        id="country_code"
        value={countryCode || DEFAULT_SELECT_VALUE}
        ref={register({ required: true })}
        onChange={({ target: { value } }) =>
          onCountryChange(value as CountryCodes)
        }
        status={errors["country_code"] ? FormFieldStatus.Danger : undefined}
        data-testid={CountrySelectorDataTestIDs.CountryResidenceSelector}
      >
        <Option disabled value={DEFAULT_SELECT_VALUE} hidden>
          {i18n._(
            t({
              id: "collect-customer-details-page.option.select-default",
              message: "Please select your country",
            })
          )}
        </Option>
        {sortedCountryCodes.map((country) => {
          const countryKey = country as CountryCodes;

          return (
            <Option key={country} value={country}>
              {`${countryCodeToName(i18n)[countryKey]} `}
            </Option>
          );
        })}
      </Select>
    </Field>
  );
};

export default CountrySelector;
