import {
  Box,
  Color,
  Field,
  FormFieldStatus,
  Hint,
  Input,
  Label,
  Text,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useCallback } from "react";
import { FormContextValues, NestDataObject } from "react-hook-form";
import {
  CustomerDetailsObject,
  getRequiredErrorTranslated,
} from "src/config/customer-details/customer-details-config";

/**
 * This type of field acts as a projection of the form state, and
 * when the input's value change, it updates the value in the form state.
 *
 * Control of the field behaviour is with the form state.
 */

export interface ControlledCustomerDetailsFieldProps {
  fieldName: keyof CustomerDetailsObject;
  labelName: string;
  errors: NestDataObject<CustomerDetailsObject>;
  register: FormContextValues["register"];
  setValue: FormContextValues["setValue"];
  watch: FormContextValues["watch"];
  defaultValue?: string | null;
  requiredField?: boolean;
  description?: string;
  gridArea?: string;
  type?: string;
}

const ControlledCustomerDetailsField = ({
  fieldName,
  labelName,
  errors,
  register,
  setValue,
  watch,
  defaultValue,
  requiredField = true,
  description,
  gridArea = "",
  type,
}: ControlledCustomerDetailsFieldProps) => {
  const error = errors[fieldName];
  const translatedRequiredFieldText = getRequiredErrorTranslated(fieldName);
  const value = watch(fieldName, defaultValue ?? "");
  const onChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setValue(fieldName, event.target.value, true),
    [fieldName, setValue]
  );
  return (
    <Box gridArea={gridArea}>
      <Field>
        <Label htmlFor={fieldName}>
          {labelName}{" "}
          {!requiredField && (
            <Text color={Color.Greystone_1100} size={2}>
              <Trans id="collect-customer-details-page.optional-field">
                (optional)
              </Trans>
            </Text>
          )}
        </Label>
        {description && <Hint>{description}</Hint>}
        <Input
          name={fieldName}
          id={fieldName}
          value={value}
          onChange={onChangeInput}
          status={error ? FormFieldStatus.Danger : undefined}
          type={type}
          ref={register({
            required: requiredField && translatedRequiredFieldText,
          })}
        />
        {error && (
          <Hint status={FormFieldStatus.Danger}>
            {["required", "invalid", "pattern"].includes(error.type) &&
              error.message}
          </Hint>
        )}
      </Field>
    </Box>
  );
};

export default ControlledCustomerDetailsField;
