import { useContext } from "react";
import { NextPage } from "next";
import CollectCustomerDetails from "src/components/pages/CollectCustomerDetails";
import ErrorsBoundary from "src/components/shared/ErrorsBoundary";
import EmailVerificationBoundary from "src/components/shared/EmailVerificationBoundary";
import LoadingBoundary from "src/state/StateInitialiser";
import { Router, Routes } from "src/components/shared/Router";
import { SessionInitialiser } from "src/state/SessionInitialiser";
import BillingRequestInitialiser from "src/state/BillingRequestInitialiser";
import ResourceInitialiserForRoute from "src/state/ResourceInitialiserForRoute";
import Layout from "src/components/shared/Layout";
import { GlobalState } from "src/state";

const Page: NextPage<{}> = () => {
  const { billingRequest } = useContext(GlobalState);

  return (
    <Layout page={Routes.CollectCustomerDetails}>
      <ErrorsBoundary>
        <SessionInitialiser />
        <EmailVerificationBoundary>
          <BillingRequestInitialiser />
          <ResourceInitialiserForRoute
            page={Routes.CollectCustomerDetails}
            billingRequest={billingRequest}
          />

          <LoadingBoundary
            requiredResources={{
              billingRequest: true,
            }}
          >
            <Router>
              <CollectCustomerDetails />
            </Router>
          </LoadingBoundary>
        </EmailVerificationBoundary>
      </ErrorsBoundary>
    </Layout>
  );
};

export default Page;
