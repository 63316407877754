import {
  BillingRequestResource,
  CustomerBillingDetailObject,
  PrefilledCustomerPrefilledCustomer,
} from "@gocardless/api/dashboard/types";
import { I18n } from "@lingui/core";
import { isEmpty } from "lodash";
import { FormContextValues, NestDataObject } from "react-hook-form";
import {
  CustomerDetailsObject,
  FieldType,
  getComplianceFieldsForSchemeTranslatedLabel,
} from "src/config/customer-details/customer-details-config";

import CustomerField from "../CustomerField";

interface ComplianceFieldType extends Omit<FieldType, "name"> {
  name: keyof CustomerBillingDetailObject;
  default: string | null | undefined;
}

export const getComplianceFieldsForPayerType = ({
  billingRequest,
  brfPrefilledCustomer,
  complianceFields = {},
  isCompany = false,
}: {
  billingRequest: BillingRequestResource;
  brfPrefilledCustomer?: PrefilledCustomerPrefilledCustomer | null;
  complianceFields?: Record<string, FieldType>;
  isCompany?: boolean;
}): ComplianceFieldType[] => {
  if (!complianceFields || isEmpty(complianceFields)) {
    return [];
  }

  // filter out only-business-relevant compliance fields for non-business flows
  const relevantComplianceFields = Object.values(complianceFields).filter(
    (field) => isCompany || !field.relevantToCompanyOnly
  );

  return relevantComplianceFields.map((field) => {
    const fieldName = field.name as keyof CustomerBillingDetailObject;
    const defaultValue =
      billingRequest.resources?.customer_billing_detail?.[fieldName] ||
      brfPrefilledCustomer?.[
        fieldName as keyof PrefilledCustomerPrefilledCustomer
      ];
    const fieldLabel = getComplianceFieldsForSchemeTranslatedLabel(
      field,
      isCompany
    );

    return {
      ...field,
      name: fieldName,
      label: fieldLabel,
      default: defaultValue,
    } as ComplianceFieldType;
  });
};

export const SchemeComplianceFields = ({
  register,
  errors,
  complianceFields,
  i18n,
}: {
  register: FormContextValues["register"];
  errors: NestDataObject<CustomerDetailsObject>;
  complianceFields: ComplianceFieldType[];
  i18n: I18n;
}): React.ReactElement => {
  return (
    <>
      {complianceFields.map((field) => {
        return (
          <CustomerField
            key={field.name}
            fieldName={field.name}
            labelName={field.label}
            errors={errors}
            register={register}
            requiredField={field.required}
            options={field.options}
            defaultValue={field.default}
            i18n={i18n}
          />
        );
      })}
    </>
  );
};

export default SchemeComplianceFields;
