import {
  BillingRequestResource,
  CustomerResource,
} from "@gocardless/api/dashboard/types";
import { Column, Grid } from "@gocardless/flux-react";
import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { FormContextValues, NestDataObject } from "react-hook-form";
import { CustomerDetailsObject } from "src/config/customer-details/customer-details-config";
import { PayerThemeType } from "src/state";

import CustomerField from "../CustomerField";

import CustomerCompanyToggleButton from "./CustomerCompanyToggleButton";

export interface CompanyDetailsFieldsProps {
  register: FormContextValues["register"];
  setUseCompanyName: React.Dispatch<boolean>;
  sendEvent: (name: string, params?: {}) => void;
  errors: NestDataObject<CustomerDetailsObject>;
  brfPrefilledCustomerData: CustomerResource;
  billingRequest: BillingRequestResource;
  payerTheme?: PayerThemeType;
  i18n: I18n;
}

export const CompanyDetailsFields = ({
  register,
  errors,
  setUseCompanyName,
  billingRequest,
  brfPrefilledCustomerData,
  i18n,
  payerTheme,
  sendEvent,
}: CompanyDetailsFieldsProps) => (
  <>
    <Grid gutterH={1}>
      <Column>
        <CustomerField
          fieldName="company_name"
          labelName={i18n._(
            t({
              id: "collect-customer-details-page.company-name.label",
              message: "Company name",
            })
          )}
          errors={errors}
          register={register}
          defaultValue={
            billingRequest?.resources?.customer?.company_name ||
            brfPrefilledCustomerData?.company_name
          }
          i18n={i18n}
        />
      </Column>
    </Grid>
    <CustomerCompanyToggleButton
      text={i18n._(
        t({
          id: "collect-customer-details-page.switch-to-name.toggle-link",
          message: "or click here to use your name",
        })
      )}
      toSwitchCompanyDetails={false}
      setUseCompanyName={setUseCompanyName}
      payerTheme={payerTheme}
      sendEvent={sendEvent}
    />
  </>
);

export default CompanyDetailsFields;
