import { ButtonVariant, FontWeight, Space } from "@gocardless/flux-react";
import { TrackingEvents } from "src/common/trackingEvents";
import { BrandedComponentType, getBrandColorFor } from "src/common/utils";
import BrandedButton from "src/components/shared/BrandedComponents/BrandedButton";
import { PayerThemeType } from "src/state";

export interface CustomerCompanyToggleButtonProps {
  text: string;
  toSwitchCompanyDetails: boolean;
  setUseCompanyName: React.Dispatch<boolean>;
  payerTheme?: PayerThemeType;
  sendEvent: (name: string, params?: {}) => void;
}

const CustomerCompanyToggleButton = ({
  text,
  toSwitchCompanyDetails,
  setUseCompanyName,
  payerTheme,
  sendEvent,
}: CustomerCompanyToggleButtonProps) => (
  <>
    <Space v={0.5} />
    <BrandedButton
      textColor={getBrandColorFor(BrandedComponentType.Link, payerTheme)}
      variant={ButtonVariant.InlineUnderlined}
      onMouseDown={() => {
        setUseCompanyName(toSwitchCompanyDetails);
        sendEvent(
          toSwitchCompanyDetails
            ? TrackingEvents.CUSTOMER_DETAILS_STEP_USE_COMPANY_NAME_CLICKED
            : TrackingEvents.CUSTOMER_DETAILS_STEP_USE_PERSONAL_NAME_CLICKED
        );
      }}
      tabIndex={-1}
      style={{
        fontWeight: FontWeight.SemiBold,
        fontSize: "14px",
      }}
    >
      {text}
    </BrandedButton>
  </>
);

export default CustomerCompanyToggleButton;
