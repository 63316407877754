import { isEmpty } from "lodash";
import {
  BillingRequestResource,
  BillingRequestFlowResource,
  CustomerBillingDetailObject,
  CustomerResource,
} from "@gocardless/api/dashboard/types";
import { billingRequestCollectCustomerDetails } from "@gocardless/api/dashboard/billing-request";
import { HTTPStatusCode } from "@gocardless/api/http/http-status-codes";
import { HTTPError } from "@gocardless/api/utils/api";
import { getErrorsFromErrorResponse } from "@gocardless/api/utils/error";
import {
  Box,
  Button,
  ButtonLayout,
  ButtonVariant,
  ButtonSize,
  Column,
  Form,
  Glyph,
  Grid,
  JustifyContent,
  Label,
  Space,
  H5,
} from "@gocardless/flux-react";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  FormContextValues,
  NestDataObject,
  useForm,
  ValidationOptions,
} from "react-hook-form";
import { CountryCodes } from "src/common/country";
import { TrackingEvents } from "src/common/trackingEvents";
import {
  BrandedComponentType,
  getBrandColorFor,
  getResidenceCountryMetadata,
  leadBillingRequestScheme,
  showError,
  isAchMxExperimentMandate,
  isEligibleForRemovePayerName,
} from "src/common/utils";
import BrandedButton from "src/components/shared/BrandedComponents/BrandedButton";
import {
  customerDetailsForSchemeAndCountry,
  CustomerFieldsConfig,
  CustomerDetailsObject,
} from "src/config/customer-details/customer-details-config";
import { ErrorType, GlobalState, PayerThemeType } from "src/state";
import { ErrorTypeEnum } from "src/state/errors";
import { CustomerDetailsAddressFields } from "src/config/customer-details/fields";
import { getItem, removeItem } from "src/legacy-ui/local-storage";
import { Scheme, customiseForScheme } from "src/common/schemeCustomisations";

import { AddressDetailsFields } from "./AddressDetails";
import CountrySelector from "./CountrySelector";
import {
  CompanyDetailsFields,
  PersonalDetailsFields,
  SchemeComplianceFields,
  getComplianceFieldsForPayerType,
} from "./CustomerDetails";
import CustomerField from "./CustomerField";

export interface CustomerDetailsFormProps {
  billingRequest: BillingRequestResource;
  billingRequestFlow: BillingRequestFlowResource;
  onContinue: () => void;
  onCancel: () => void;
  sendEvent: (name: string, params?: {}) => void;
}

const CustomerDetailsForm = ({
  billingRequest,
  billingRequestFlow,
  onContinue,
  onCancel,
  sendEvent,
}: CustomerDetailsFormProps) => {
  // Be careful to use limited global state fields, as we're a component that is
  // deep underneath a root page. We should get these fields from props, to
  // avoid implicit access/modification.
  const {
    setError: setAppError,
    setBillingRequest,
    setSelectedInstitution,
    isEditClicked,
    residenceCountryMetadata,
    setResidenceCountryMetadata,
    payerTheme,
  } = useContext(GlobalState);

  const scheme = billingRequest.mandate_request?.scheme;

  // Default whether we use a company name to whether we have one set on the
  // customer.
  const [companyNameToggle, setCompanyNameToggle] = useState<boolean>(
    !!billingRequest?.resources?.customer?.company_name ||
      !!billingRequestFlow?.prefilled_customer?.company_name
  );

  useEffect(() => {
    // This needs to be checked to avoid reset country code value as changing
    // locale renders the whole component
    if (
      residenceCountryMetadata?.countryCode &&
      residenceCountryMetadata?.customerFieldsConfig
    ) {
      return;
    }

    setResidenceCountryMetadata(
      getResidenceCountryMetadata({ billingRequest, billingRequestFlow })
    );
  }, []);

  const { customerFieldsConfig, countryCode } = residenceCountryMetadata ?? {};

  const onCountryChange = useCallback(
    (country: CountryCodes) => {
      let newCustomerFieldsConfig;
      if (scheme && country) {
        newCustomerFieldsConfig = customerDetailsForSchemeAndCountry(
          scheme,
          country,
          billingRequest
        );
      }

      setResidenceCountryMetadata({
        countryCode: country,
        customerFieldsConfig: newCustomerFieldsConfig,
      });
    },
    [scheme, billingRequest]
  );

  /**
   * If the address fields already has some values then they need to be
   * loaded in the form memory as default value.
   * When "address_line1" is defined it doesn't show AddressLookup
   */
  const defaultValues = useMemo(() => {
    const initialValues: Partial<Record<string, string>> = {};
    if (billingRequest.mandate_request) {
      const addressFields = customerFieldsConfig?.addressFields ?? {};
      Object.entries(addressFields).forEach(([_, field]) => {
        const billingDetailValue =
          billingRequest?.resources?.customer_billing_detail?.[
            field.name as keyof CustomerBillingDetailObject
          ];
        if (billingDetailValue) {
          initialValues[field.name] = billingDetailValue;
        }
      });
    }
    return initialValues;
  }, [
    billingRequest.resources?.customer_billing_detail,
    billingRequest.mandate_request,
    customerFieldsConfig?.addressFields,
  ]);

  const {
    errors,
    formState,
    getValues,
    handleSubmit,
    register,
    unregister,
    setError,
    setValue,
    watch,
  } = useForm<CustomerDetailsObject>({
    mode: "onBlur",
    defaultValues,
  });

  const [givenNameTypingEventSent, setGivenNameTypingEventSent] =
    useState(false);
  const [familyNameTypingEventSent, setFamilyNameTypingEventSent] =
    useState(false);
  const [companyNameTypingEventSent, setCompanyNameTypingEventSent] =
    useState(false);
  const [emailTypingEventSent, setEmailTypingEventSent] = useState(false);
  const [billingAddressTypingEventSent, setBillingAddressTypingEventSent] =
    useState(false);

  useEffect(() => {
    const formValues = getValues();
    if (formValues.given_name && !givenNameTypingEventSent) {
      sendEvent(TrackingEvents.CUSTOMER_DETAILS_STEP_FIRST_NAME_TYPED);
      setGivenNameTypingEventSent(true);
    }
    if (formValues.family_name && !familyNameTypingEventSent) {
      sendEvent(TrackingEvents.CUSTOMER_DETAILS_STEP_LAST_NAME_TYPED);
      setFamilyNameTypingEventSent(true);
    }
    if (formValues.company_name && !companyNameTypingEventSent) {
      sendEvent(TrackingEvents.CUSTOMER_DETAILS_STEP_COMPANY_NAME_TYPED);
      setCompanyNameTypingEventSent(true);
    }
    if (formValues.email && !emailTypingEventSent) {
      sendEvent(TrackingEvents.CUSTOMER_DETAILS_STEP_EMAIL_ADDRESS_TYPED);
      setEmailTypingEventSent(true);
    }
    if (formValues.address_line1 && !billingAddressTypingEventSent) {
      sendEvent(TrackingEvents.CUSTOMER_DETAILS_STEP_BILLING_ADDRESS_TYPED);
      setBillingAddressTypingEventSent(true);
    }
  }, [formState]);

  // we register address fields explicitly into React Hook Form
  // to be able to validate the presense of address fields
  // when they are collapsed and only AddressLookup is shown
  // in fact, this is the only use case for this useEffect
  useEffect(() => {
    const addressFields = customerFieldsConfig?.addressFields ?? {};
    if (billingRequest.mandate_request) {
      Object.entries(addressFields).forEach(([_, field]) => {
        const validationOptions: ValidationOptions = {};
        if (field.required) {
          validationOptions["required"] =
            `Enter your ${field.label.toLowerCase()}`;
        }
        register(field.name, validationOptions);
      });

      // clean-up registered address fields which aren't configured
      // after config rebuilding (currency and scheme changes)
      // to not use the validations for the previous scheme
      CustomerDetailsAddressFields.forEach((field) => {
        if (!addressFields[field]) {
          unregister(field);
        }
      });
    }
  }, [
    register,
    unregister,
    customerFieldsConfig?.addressFields,
    billingRequest.mandate_request,
  ]);

  const onSubmit = (formData: CustomerDetailsObject) => {
    // we're submitting fake names and surnames for the MX experiment
    // this data will be updated later from the customers bank
    const forceTmpValues = isAchMxExperimentMandate(
      billingRequest,
      formData.country_code as CountryCodes
    );

    // We want to submit either names or company name, not both or partial
    const customer: CustomerResource = {
      ...(!companyNameToggle && {
        given_name: forceTmpValues ? "First name" : formData.given_name,
        family_name: forceTmpValues ? "Last name" : formData.family_name,
        company_name: null,
      }),
      ...(companyNameToggle && { company_name: formData.company_name }),

      email: formData.email,
    };

    // The following field may have been filled out and then the country_code
    // changed meaning they need to be removed if no longer appopriate
    const region =
      formData.country_code === CountryCodes.US ? formData.region : undefined;

    const customer_billing_detail: CustomerBillingDetailObject | undefined =
      billingRequest?.mandate_request
        ? {
            address_line1: formData.address_line1,
            address_line2: formData.address_line2,
            city: formData.city,
            country_code: formData.country_code,
            region: region,
            postal_code: formData.postal_code,
            danish_identity_number: formData.danish_identity_number,
            swedish_identity_number: formData.swedish_identity_number,
          }
        : undefined;

    billingRequestCollectCustomerDetails(billingRequest.id, {
      customer: customer,
      customer_billing_detail: customer_billing_detail,
    })
      .then((resp) => {
        sendEvent(TrackingEvents.CUSTOMER_DETAILS_STEP_COMPLETED);
        setBillingRequest(resp.billing_requests);
        // If we have an institution on the billing request resource use that
        // as it was likely provided by the backend as a guess.
        setSelectedInstitution(resp.billing_requests?.resources?.institution);
      })
      .then(onContinue)
      .catch(async (errorRes: HTTPError) => {
        if (errorRes?.response?.status !== HTTPStatusCode.UnprocessableEntity) {
          // call showError only when not a validation error
          showError(
            errorRes,
            setAppError,
            "CustomerDetailsForm",
            ErrorTypeEnum.ApiError
          );
        } else {
          const errorResponse = await getErrorsFromErrorResponse(errorRes);
          if (errorResponse.length > 0) {
            errorResponse.forEach((error) => {
              const errorKey = error.field;
              if (errorKey) {
                const fieldFromRequest = error.request_pointer.replace(
                  "/data/",
                  ""
                );
                if (
                  Object.prototype.hasOwnProperty.call(
                    formData,
                    fieldFromRequest
                  )
                ) {
                  setError(fieldFromRequest, "invalid", error.message);
                } else {
                  setError(errorKey, "invalid", error.message);
                }
              }
            });
          } else {
            // might not be required actually. Just to be safe, if we miss anything
            setAppError({
              errorType: ErrorTypeEnum.ApiError,
              errorMessage: "CustomerDeatilsForm: Uncaught API error",
            });
          }
        }
      });
  };

  return (
    <CustomerDetailsView
      register={register}
      onSubmit={handleSubmit(onSubmit)}
      errors={errors}
      useCompanyName={companyNameToggle}
      setUseCompanyName={setCompanyNameToggle}
      billingRequest={billingRequest}
      billingRequestFlow={billingRequestFlow}
      isEditClicked={isEditClicked}
      onCancel={onCancel}
      countryCode={countryCode}
      onCountryChange={onCountryChange}
      schemeConfigFields={customerFieldsConfig}
      setValue={setValue}
      watch={watch}
      setAppError={setAppError}
      payerTheme={payerTheme}
      sendEvent={sendEvent}
    />
  );
};

export interface CustomerDetailsViewProps {
  setAppError: (error?: ErrorType) => void;
  useCompanyName: boolean;
  errors: NestDataObject<CustomerDetailsObject>;
  isEditClicked: boolean;
  register: FormContextValues["register"];
  setValue: FormContextValues["setValue"];
  watch: FormContextValues["watch"];
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  setUseCompanyName: React.Dispatch<boolean>;
  billingRequest: BillingRequestResource;
  billingRequestFlow: BillingRequestFlowResource;
  onCancel: () => void;
  countryCode?: CountryCodes;
  onCountryChange: (country: CountryCodes) => void;
  schemeConfigFields?: CustomerFieldsConfig;
  payerTheme?: PayerThemeType;
  sendEvent: (name: string, params?: {}) => void;
}

export const CustomerDetailsView = ({
  useCompanyName,
  errors,
  isEditClicked,
  register,
  onSubmit,
  setUseCompanyName,
  billingRequest,
  billingRequestFlow,
  onCancel,
  countryCode,
  onCountryChange,
  schemeConfigFields,
  setValue,
  watch,
  setAppError,
  payerTheme,
  sendEvent,
}: CustomerDetailsViewProps) => {
  const isCountryGB = countryCode === CountryCodes.GB;
  const countrySelectionRequired = !!billingRequest.mandate_request || false;
  const brfPrefilledCustomer = billingRequestFlow?.prefilled_customer;
  const leadScheme = leadBillingRequestScheme(billingRequest) as Scheme;
  // TODO: update when we decide what to do with corporate mandates
  const shouldShowPersonalFields =
    isEmpty(schemeConfigFields) || !isEmpty(schemeConfigFields?.personalFields);
  const noteForCompanies =
    useCompanyName &&
    customiseForScheme({
      scheme: leadScheme,
      key: "billing-request.collect-customer-details.corporate-payers-note",
      params: { billingRequest },
    });

  // Leaving this here as we will need this i18n to translate the labels
  const { i18n } = useLingui();

  function getEmail() {
    const customerEmail = billingRequest?.resources?.customer?.email;
    const prefilledEmail = brfPrefilledCustomer?.email;
    const customerEmailFromLocalStorageKey = `${billingRequestFlow?.id}-prefill-email`;
    const emailInLocalStorage = getItem(customerEmailFromLocalStorageKey);
    if (emailInLocalStorage) {
      removeItem(customerEmailFromLocalStorageKey);
    }
    // Precedence goes BRQ > BRF > Local Storage
    return customerEmail || prefilledEmail || emailInLocalStorage;
  }

  const complianceFields = getComplianceFieldsForPayerType({
    billingRequest,
    brfPrefilledCustomer,
    complianceFields: schemeConfigFields?.schemeComplianceFields,
    isCompany: useCompanyName,
  });

  const shouldShowPayerNameFields =
    !isEligibleForRemovePayerName(billingRequest);

  return (
    <Form onSubmit={onSubmit} noValidate>
      {billingRequest.mandate_request && (
        <Box>
          <Label>
            <Label>
              <Trans id="collect-customer-details-page.country-of-residence.label">
                Country of residence
              </Trans>
            </Label>
          </Label>
          <CountrySelector
            register={register}
            errors={errors}
            countryCode={countryCode}
            onCountryChange={onCountryChange}
          />
          <Space v={2} />
        </Box>
      )}
      {(!countrySelectionRequired || countryCode) && (
        <>
          <H5>
            {useCompanyName ? (
              <Trans id="collect-customer-details-page.company-details.title">
                Your company details
              </Trans>
            ) : (
              <Trans id="collect-customer-details-page.personal-details.title">
                Your personal details
              </Trans>
            )}
          </H5>
          <Space v={1} />
          {shouldShowPayerNameFields && shouldShowPersonalFields && (
            <>
              <Box>
                {useCompanyName ? (
                  <CompanyDetailsFields
                    register={register}
                    errors={errors}
                    setUseCompanyName={setUseCompanyName}
                    billingRequest={billingRequest}
                    brfPrefilledCustomerData={
                      brfPrefilledCustomer as CustomerResource
                    }
                    i18n={i18n}
                    payerTheme={payerTheme}
                    sendEvent={sendEvent}
                  />
                ) : (
                  <PersonalDetailsFields
                    register={register}
                    errors={errors}
                    setUseCompanyName={setUseCompanyName}
                    billingRequest={billingRequest}
                    brfPrefilledCustomerData={
                      brfPrefilledCustomer as CustomerResource
                    }
                    i18n={i18n}
                    payerTheme={payerTheme}
                    sendEvent={sendEvent}
                  />
                )}
              </Box>
              <Space v={2} />
            </>
          )}
          <Box>
            <CustomerField
              fieldName="email"
              labelName={i18n._(
                t({
                  id: "collect-customer-details-page.email.label",
                  message: "Email address",
                })
              )}
              errors={errors}
              register={register}
              type="email"
              description={i18n._(
                t({
                  id: "collect-customer-details-page.email.description",
                  message:
                    "We'll only use this to keep you updated about your payment",
                })
              )}
              defaultValue={getEmail()}
              i18n={i18n}
            />
          </Box>
          <Space v={2} />
        </>
      )}
      {billingRequest.mandate_request && complianceFields.length > 0 && (
        <>
          <Box>
            <Grid gutterH={1}>
              <Column>
                <SchemeComplianceFields
                  register={register}
                  errors={errors}
                  complianceFields={complianceFields}
                  i18n={i18n}
                />
              </Column>
            </Grid>
          </Box>
          <Space v={1} />
        </>
      )}
      {billingRequest.mandate_request &&
        schemeConfigFields?.addressFields &&
        Object.keys(schemeConfigFields.addressFields).length > 0 && (
          <>
            <AddressDetailsFields
              register={register}
              setAppError={setAppError}
              setValue={setValue}
              watch={watch}
              canUseAddressLookup={isCountryGB}
              errors={errors}
              addressConfigFields={schemeConfigFields.addressFields}
              i18n={i18n}
              billingRequest={billingRequest}
              brfPrefilledCustomerData={
                brfPrefilledCustomer as CustomerBillingDetailObject
              }
              payerTheme={payerTheme}
              sendEvent={sendEvent}
              countryCode={countryCode}
            />
            <Space v={1} />
          </>
        )}

      {noteForCompanies && (
        <Box data-testid="corporate-payers-note">
          {noteForCompanies}
          <Space v={1} />
        </Box>
      )}

      <Space v={0.75} />
      <Box layout="flex" justifyContent={JustifyContent.FlexStart}>
        <BrandedButton
          disabled={countrySelectionRequired && !countryCode}
          type="submit"
          rightIcon={Glyph.ArrowForward}
          size={ButtonSize.Lg}
          variant={ButtonVariant.PrimaryOnLight}
          layout={[
            isEditClicked ? ButtonLayout.Inline : ButtonLayout.Full,
            ButtonLayout.Inline,
          ]}
          backgroundColor={getBrandColorFor(
            BrandedComponentType.Button,
            payerTheme
          )}
        >
          <Trans id="collect-customer-details-page.continue-button">
            Continue
          </Trans>
        </BrandedButton>
        {isEditClicked && <CancelButton onCancel={onCancel} />}
      </Box>
    </Form>
  );
};

const CancelButton = ({ onCancel }: { onCancel: () => void }) => {
  return (
    <Button
      layout={ButtonLayout.Inline}
      size={ButtonSize.Md}
      variant={ButtonVariant.TextOnLight}
      onClick={onCancel}
      className="fs-unmask"
      css={{ marginLeft: "5px" }}
    >
      <Trans id="collect-customer-details-page.cancel-button">Cancel</Trans>
    </Button>
  );
};

export default CustomerDetailsForm;
