import {
  BillingRequestResource,
  CustomerResource,
} from "@gocardless/api/dashboard/types";
import { Column, Grid } from "@gocardless/flux-react";
import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { FormContextValues, NestDataObject } from "react-hook-form";
import { CustomerDetailsObject } from "src/config/customer-details/customer-details-config";
import { PayerThemeType } from "src/state";

import CustomerField from "../CustomerField";

import CustomerCompanyToggleButton from "./CustomerCompanyToggleButton";

export interface PersonalDetailsFieldsProps {
  register: FormContextValues["register"];
  setUseCompanyName: React.Dispatch<boolean>;
  sendEvent: (name: string, params?: {}) => void;
  errors: NestDataObject<CustomerDetailsObject>;
  brfPrefilledCustomerData: CustomerResource;
  billingRequest: BillingRequestResource;
  payerTheme?: PayerThemeType;
  i18n: I18n;
  isEVarinatOn?: boolean;
}

export const PersonalDetailsFields = ({
  register,
  errors,
  setUseCompanyName,
  billingRequest,
  brfPrefilledCustomerData,
  i18n,
  payerTheme,
  sendEvent,
  isEVarinatOn,
}: PersonalDetailsFieldsProps) => (
  <>
    <Grid gutterH={1}>
      <Column size={[6]}>
        <CustomerField
          fieldName="given_name"
          labelName={i18n._(
            t({
              id: "collect-customer-details-page.first-name.label",
              message: "First name",
            })
          )}
          errors={errors}
          register={register}
          defaultValue={
            billingRequest?.resources?.customer?.given_name ||
            brfPrefilledCustomerData?.given_name
          }
          i18n={i18n}
        />
      </Column>

      <Column size={[6]}>
        <CustomerField
          fieldName="family_name"
          labelName={i18n._(
            t({
              id: "collect-customer-details-page.last-name.label",
              message: "Last name",
            })
          )}
          errors={errors}
          register={register}
          defaultValue={
            billingRequest?.resources?.customer?.family_name ||
            brfPrefilledCustomerData?.family_name
          }
          i18n={i18n}
        />
      </Column>
    </Grid>

    <CustomerCompanyToggleButton
      text={
        isEVarinatOn
          ? "Or use a company name"
          : i18n._(
              t({
                id: "collect-customer-details-page.switch-to-company-name.toggle-link",
                message: "or click here to use a company name",
              })
            )
      }
      toSwitchCompanyDetails
      setUseCompanyName={setUseCompanyName}
      payerTheme={payerTheme}
      sendEvent={sendEvent}
    />
  </>
);

export default PersonalDetailsFields;
